import React, { useEffect, useContext } from "react"
import { graphql } from "gatsby"
// import SEO from "../components/seo"
import styled from '@emotion/styled'
import formatDates, { formatAnchor } from "../utils/formatDates"
import { useMerch } from "../hooks/useMerch"
import { MIN_DEFAULT_MQ } from "../utils/presets"
import { CurrentContext } from "../layouts"
import Body from "../components/Body"


const Article = styled(`article`)({
  margin: `0 0 2rem 0`,
  minHeight: `26rem`,
  [MIN_DEFAULT_MQ]: {
    maxWidth: '40rem'
  }
})

const Header = styled(`header`)({
  marginBottom: `1rem`,
  [`&::before`]: {
    display: `block`,
    content: `" "`,
    visibility: `hidden`,
    pointerEvents: `none`,
    marginTop: `-76px`,
    height: `76px`,
    [MIN_DEFAULT_MQ]: {
      marginTop: `-64px`,
      height: `64px`,
    }
  }
})

const BookVinylPage = ({ data, location }) => {
  const merch = useMerch()

  return (
    <React.Fragment>
      {/*<SEO title="All merch" />*/}
      {merch.map(({ node }) => {
        const { displayDate } = formatDates(node.startDate, node.endDate, node.yearOnly)
        return (
          <Article key={node.slug.current} id={formatAnchor(node.startDate)}>
            <Header>
              <h3>
                <a href={node.eventLink} target="_blank">{displayDate}</a>
              </h3>
            </Header>
            <Body value={node._rawBody} textDecoration={'underline'} />
          </Article>
        )
      })}
    </React.Fragment>
  )
}

export default BookVinylPage
